<template>
  <v-container fluid grid-list-xl>
    <v-layout>
      <v-flex class="pt-0 pb-2">
        <page-title text="Gestione"></page-title>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-layout>
            <v-flex pt-0 pb-0>
              <v-text-field
                light
                v-model="search"
                prepend-icon="search"
                @click:prepend="getBancali"
                append-icon="cancel"
                @click:append="resetItems"
                placeholder="Cerca codice bancale" />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex class="pa-0 ml-4 pl-2">
              <v-btn class="ml-0" flat @click="toggleRicerca()">
                Ricerca avanzata
                <v-icon v-if="!showRicerca">arrow_right</v-icon>
                <v-icon v-if="showRicerca">arrow_drop_down</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout class="mb-2" v-if="showRicerca">
            <v-flex>
              <v-layout class="ml-4">
                <v-flex xs3>
                  <v-text-field
                    label="Descrizione"
                    v-model="ricerca.descrizione" />
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    label="Componente"
                    v-model="ricerca.componente" />
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    label="Codice"
                    v-model="ricerca.codice" />
                </v-flex>
                <v-flex xs3>
                </v-flex>
              </v-layout>
              <v-layout class="ml-4">
                <v-flex xs3>
                  <v-text-field
                    label="Opera"
                    v-model="ricerca.opera" />
                </v-flex>
                <v-flex xs3>
                  <v-select
                    class="mt-0"
                    label="Stato"
                    :items="stati"
                    item-text="text"
                    item-value="val"
                    v-model="ricerca.stato" />
                </v-flex>
                <v-flex xs3 class="pa-2">
                  <v-btn
                    class="ml-0"
                    color="primary"
                    @click.native="cerca()">
                    Filtra
                  </v-btn>
                  <v-btn
                    @click="reset_cerca()">
                    Ripristina
                  </v-btn>
                </v-flex>
                <v-flex xs3>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!bancali.length">
          Nessun bancale trovato
        </v-alert>
        <v-layout row wrap v-if="bancali.length">
          <v-flex xs12 class="text-md-right">
            <v-tooltip bottom>
               <v-btn slot="activator" color="info" @click="giacenza()">Giacenza</v-btn>
               Avvia elaborazione
            </v-tooltip>
            <v-tooltip bottom>
               <v-btn slot="activator" color="info" @click="informe()">Dettaglio</v-btn>
               Avvia elaborazione
            </v-tooltip>
            <v-tooltip bottom>
               <v-btn slot="activator" color="info" @click="desglose()">Dettaglio esteso</v-btn>
               Avvia elaborazione
            </v-tooltip>
          </v-flex>
        </v-layout>
        <v-list class="mt-2" three-line v-if="bancali.length">
          <v-list-group
            class="listIcon"
            sub-group
            no-action
            v-for="(bancale, index) in bancali"
            v-model="bancale.active"
            :key="'tile-'+bancale._id">
            <v-list-tile slot="activator" avatar>
              <div v-on:click.stop class="chipPosition">
                <v-chip
                  color="green"
                  text-color="white"
                  v-if="!bancale.padre_id && bancale.qta_totale > bancale.qta_prelevata && bancale.posizione && bancale.posizione !== 'TMP'">
                  <span v-if="bancale.sede===1">Cormano-</span>
                  <span v-else>Paderno-</span>
                  {{bancale.posizione}}
                </v-chip>
                <v-chip
                  color="grey"
                  text-color="white"
                  v-if="!bancale.padre_id && bancale.qta_totale > bancale.qta_prelevata && bancale.posizione && bancale.posizione === 'TMP'">
                  <span v-if="bancale.sede===1">Cormano-</span>
                  <span v-else>Paderno-</span>
                  TMP
                </v-chip>
                <v-chip
                  color="yellow"
                  text-color="black"
                  v-if="!bancale.padre_id && bancale.qta_totale > bancale.qta_prelevata && !bancale.posizione">
                  <span v-if="bancale.sede===1">Cormano-</span>
                  <span v-else>Paderno-</span>
                  +
                </v-chip>
                <v-chip
                  color="red"
                  text-color="white"
                  v-if="bancale.padre_id || bancale.qta_totale === bancale.qta_prelevata">
                  <span v-if="bancale.sede===1">Cormano-</span>
                  <span v-else>Paderno-</span>
                  <span v-if="bancale.ddt_id">Uscito</span>
                  <span v-else>In uscita</span>
                </v-chip>
              </div>
              <v-list-tile-content class="ml-4">
                <v-list-tile-title>
                  <span v-if="bancale.progressivo">{{bancale.num_bancale}}</span>
                  <span v-else>{{bancale._id}} - {{bancale.ragione_sociale}}</span>
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  Qta
                  <span v-if="bancale.padre_id">{{bancale.qta_prelevata}}</span>
                  <span v-else>{{bancale.qta_totale}} - (rimanenza {{bancale.qta_totale - bancale.qta_prelevata}})</span>
                  <span v-if="bancale.prelievi"> - </span>
                  <span class="secondary--text underline--text"
                    v-if="bancale.prelievi === 1 && !bancale.padre_id"
                    @click.stop="openStorico(bancale._id)">
                    1 prelievo
                  </span>
                  <span class="secondary--text underline--text"
                    v-if="bancale.prelievi > 1 && !bancale.padre_id"
                    @click.stop="openStorico(bancale._id)">
                    {{bancale.prelievi}} prelievi
                  </span>
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>
                  {{bancale.descrizione}}
                  <v-tooltip right v-if="bancale.more_data.length">
                    <span slot="activator" class="secondary--text"> +{{bancale.more_data.length}}</span>
                    <span v-for="(item, index) in bancale.more_data" :key="'more-'+index">
                      {{item.descrizione}}<br />
                    </span>
                  </v-tooltip>
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile v-for="comp in bancale.componenti" :key="comp.componente_id">
              <v-list-tile-content>
                <v-list-tile-title class="rowWrapper">
                  <v-layout row wrap class="rowWrapper-row">
                    <v-flex sm12 lg2>
                      <v-text-field
                        label="Opera"
                        v-model="comp.opera"
                        disabled />
                    </v-flex>
                    <v-flex sm12 lg3>
                      <v-text-field
                        label="Descrizione"
                        v-model="comp.descrizione"
                        disabled />
                    </v-flex>
                    <v-flex sm12 lg1>
                      <v-text-field
                        label="Codice"
                        v-model="comp.codice"
                        disabled />
                    </v-flex>
                    <v-flex sm12 lg2>
                      <v-text-field
                        label="Componente"
                        v-model="comp.componente"
                        disabled />
                    </v-flex>
                    <v-flex sm12 lg2>
                      <v-text-field
                        label="Note"
                        v-model="comp.note"
                        disabled />
                    </v-flex>
                    <v-flex sm12 lg1>
                    </v-flex>
                    <v-flex sm12 lg1>
                      <v-text-field
                        label="Qtà"
                        v-model="comp.qta"
                        disabled />
                    </v-flex>
                  </v-layout>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider :key="'divider-'+bancale._id" v-if="index < bancali.length - 1" inset></v-divider>
          </v-list-group>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
    </v-layout>
    <storico-prelievi ref="storicoPrelievi" />
    <informe ref="informe" />
    <desglose ref="desglose" />
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import PageTitle from '@/components/common/PageTitle'
import storicoPrelievi from '@/components/esterni/StoricoPrelievi'
import informe from '@/components/esterni/Informe'
import desglose from '@/components/esterni/Desglose'

export default {
  data: () => ({
    bancali: [],
    bancale: {},
    componente: {},
    search: null,
    pages: 0,
    page: 1,
    paginate: 20,
    showRicerca: false,
    intmp: false,
    ricerca: {},
    stati: [
      { val: 0, text: 'In uscita/Usciti' },
      { val: 1, text: 'Posizionati' },
      { val: 2, text: 'Da posizionare' },
      { val: 3, text: 'TMP' }
    ]
  }),
  components: {
    PageTitle,
    storicoPrelievi,
    informe,
    desglose
  },
  methods: {
    isEmpty (obj) {
      return (obj.keys && obj.keys.length)
    },
    openStorico (id) {
      this.$refs.storicoPrelievi.init(id)
      this.$refs.storicoPrelievi.show()
    },
    informe () {
      this.$refs.informe.show()
    },
    desglose () {
      this.$refs.desglose.show()
    },
    giacenza () {
      this.$plsqlMethod('esterni', 'giacenza', { })
        .then(response => {
          this.$http({
            method: 'post',
            url: '/excel/giacenza',
            responseType: 'blob',
            data: response.data
          }).then((giacenza) => {
            const blob = new Blob([giacenza.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            this.$openFileNewTab(blob, 'giacenza.xlsx') // con adblocker picche ocio
          }).catch((err) => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare l\'excel della giacenza' })
          })
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura giacenza' })
        })
    },
    getBancali () {
      let o = {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      }
      if (!this.isEmpty(this.ricerca)) o.ricerca = this.ricerca

      let processData = function (ban) {
        ban.descrizione = null
        ban.more_data = []
        ban.componenti.forEach(function (item) {
          let desc = ''
          if (item.opera) desc += item.opera
          if (item.componente) desc += ' - ' + item.componente
          if (item.descrizione && item.descrizione !== '-') desc += ' - ' + item.descrizione
          desc += ' Qta ' + item.qta
          if (ban.descrizione === null) {
            ban.descrizione = desc
          } else {
            ban.more_data.push({ descrizione: desc })
          }
        })
      }

      this.$plsqlReadAll('esterni', o)
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.bancali = response.data.items
            this.pages = response.data.pages

            this.bancali.forEach(function (ban) {
              processData(ban)
            })
          }
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore' })
        })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getBancali()
    },
    cerca () {
      this.getBancali()
    },
    reset_cerca () {
      if (!this.isEmpty(this.ricerca)) {
        this.ricerca = {}
        this.changePage(1)
      }
    },
    toggleRicerca () {
      this.showRicerca = !this.showRicerca
    }
  },
  mounted () {
    this.getBancali()
  }
}
</script>
<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
  .underline--text {
    text-decoration: underline;
  }
  .chipPosition {
    min-width: 150px;
    text-align: left;
  }
</style>
